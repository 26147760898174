import GLightbox from 'glightbox';


// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    const moreLogosButtons = document.querySelectorAll('.js-logos-more-button');
    if (moreLogosButtons.length) {
        for (const button of moreLogosButtons) {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                const parent = button.closest('.js-logos');
                const moreContainer = parent.querySelector('.js-logos-more-container');

                if (moreContainer) {
                    if (moreContainer.classList.contains('hidden')) {
                        moreContainer.classList.remove('hidden');
                        button.querySelector('.js-more').classList.add('hidden');
                        button.querySelector('.js-less').classList.remove('hidden');
                    } else {
                        moreContainer.classList.add('hidden');
                        button.querySelector('.js-more').classList.remove('hidden');
                        button.querySelector('.js-less').classList.add('hidden');
                    }
                }
                return false;

            });
        }
    }


    const notice = document.querySelector('.js-notice');
    if (notice) {
        const noticeClose = notice.querySelector('.notice__close');
        let date = window.localStorage.getItem('notice_closed');
        let now = Date.now();
        console.log(date, now);
        if (date) {
            const diff = (now - date) / (1000 * 3600 * 24);
            if (diff < 1) {
                notice.classList.add('hidden');
            } else {
                notice.classList.remove('hidden');
            }
        } else {
            notice.classList.remove('hidden');
        }

        noticeClose.addEventListener('click', function(e) {
            e.preventDefault();
            notice.classList.add('hidden');
            window.localStorage.setItem('notice_closed', now);
            return false;
        });

    }

    const accordionTitles = document.querySelectorAll('.js-accordion-title');
    if (accordionTitles.length) {

        for (const accordionTitle of accordionTitles) {
            accordionTitle.addEventListener('click', (e) => {

                const list = accordionTitle.closest('.js-accordion-list');
                const parent = accordionTitle.parentNode;

                if (parent.classList.contains('js-active')) {
                    parent.classList.remove('js-active');
                    parent.classList.remove('active');
                } else {
                    const activeItems = list.querySelectorAll('.js-accordion-item.js-active.active');
                    for (const activeItem of activeItems) {
                        activeItem.classList.remove('js-active');
                        activeItem.classList.remove('active');
                    }

                    parent.classList.add('js-active');
                    parent.classList.add('active');
                }

            });
        }

    }


    const staffButtons = document.querySelectorAll('.js-staff-button');
    if (staffButtons.length) {

        for (const staffButton of staffButtons) {
            staffButton.addEventListener('click', () => {
                const card = staffButton.closest('.js-card');
                if (card.classList.contains('active')) {
                    card.classList.remove('active');
                } else {
                    card.classList.add('active');
                }
            })
        }
    }


    const forms = document.querySelectorAll('form[data-ajax]');
    if (forms.length) {
        for (const form of forms) {

            form.addEventListener('freeform-ajax-success', (e) => {
                const formSubmitted = e.form;
                formSubmitted.classList.add('success');
                window.setTimeout(() => {
                    formSubmitted.scrollIntoView({ behavior: 'smooth', block: 'start'});
                }, 100);

            });

            // Override the way field errors are displayed
            form.addEventListener('freeform-render-field-errors', function(event) {
                // Prevent the default behaviour
                event.preventDefault();

                const errors = event.errors;
                for (const key in errors) {
                    if (!errors.hasOwnProperty(key) || !key) {
                        continue;
                    }

                    const messages = errors[key];
                    const errorsList = document.createElement("p");
                    errorsList.classList.add("ff-errors");
                    errorsList.classList.add("error");
                    errorsList.classList.add("text-error");
                    errorsList.classList.add("font-sansBold");
                    errorsList.classList.add("mt-2");
                    errorsList.innerHTML = messages.join('<br>');

                    const inputList = form.querySelectorAll("*[name=" + key + "], *[name='" + key + "[]']");
                    for (let inputIndex = 0; inputIndex < inputList.length; inputIndex++) {
                        const input = inputList[inputIndex];
                        input.classList.add("this-field-has-errors");
                        input.parentElement.appendChild(errorsList);
                    }
                }
            });
        }
    }

    const navTriggerOpen = document.querySelector('.js-open-nav');
    const navTriggerClose = document.querySelector('.js-close-nav');

    navTriggerOpen.addEventListener('click', (e) => {
        e.preventDefault();
        document.querySelector('html').classList.add('active-mm');
        return false;
    })

    navTriggerClose.addEventListener('click', (e) => {
        e.preventDefault();
        document.querySelector('html').classList.remove('active-mm');
        return false;
    });



    const embeds = document.querySelectorAll('.js-video-embed');
    for (const embed of embeds) {
        const url = embed.getAttribute('data-url');
        let embedCode = '';
        if (url) {
            if (isYoutube(url)) {
                const youtubeId = getYoutubeId(url);
                const embedUrl = '//www.youtube.com/embed/' + youtubeId;
                embedCode = '<iframe src="' + embedUrl + '" ' +
                    'allow="autoplay" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
            } else {
                const vimeoId = getVimeoId(url);
                const embedUrl = '//player.vimeo.com/video/' + vimeoId;
                embedCode = '<iframe src="' + embedUrl + '" ' +
                    'allow="autoplay" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
            }
        }
        embed.innerHTML = embedCode;
    }

    function getYoutubeId(url)
    {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11 ) ? match[7] : false;
    }

    function getVimeoId(url)
    {
        const regExp = /http:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        const match = url.match(regExp);
        return match[2];
    }

    function isYoutube(url) {
        return url.indexOf('youtube') >= 0 || url.indexOf('youtu.be') >= 0;
    }

    const lightbox = GLightbox({
        touchNavigation: true,
        autoplayVideos: true
    });


    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
